import RepositoryBase, {IFindOption} from "@/repositories/repository-base";
import CustomCache from "@/utils/custom-cache";
import {MadeInAreaEntity} from "@/entities/ingredient-made-in-area-entity";
import appType from "@/app-types";

export class FaqRepository extends RepositoryBase<any> {
  protected endpoint = "faqs";
  protected ctor:new(data) => any = Object;

  protected static cache:CustomCache<any[]> = new CustomCache();

  public async findAllWithCache(): Promise<any[]> {
    return await this.handleCache(FaqRepository.cache, async () => {
      const res = await this.get(this.endpoint, {
        appType: appType
      });
      return res.data;
    });
  }
}
export default new FaqRepository;
