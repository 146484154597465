

















import { Component, Vue, Prop} from 'vue-property-decorator';
@Component
export default class extends Vue {
  @Prop({required: true})
  public list!:any;
}
