




































import { Vue, Component } from "vue-property-decorator";
import LoadingHandler from "@/utils/loading-handler";

import FAQCategoryRepository from "@/repositories/faq-repository";
import {dispatchInitialized} from "@/libs/cypress";

@Component
export default class extends Vue {
  private faqCategories: any = null;

  protected mounted() {
    LoadingHandler(async () => {
      this.faqCategories = await FAQCategoryRepository.findAllWithCache();
    }).then(() => {
      dispatchInitialized();
    });
  }
}
