



















import { Vue, Component, Prop } from 'vue-property-decorator';
import LoadingHandler from '@/utils/loading-handler';
import { setTitle } from '@/utils/seo-meta';

import FAQRepository from '@/repositories/faq-repository';
import BreadCrumb from '@/components/Breadcrumb.vue';
import {dispatchInitialized} from "@/libs/cypress";

@Component({
  components: {
    BreadCrumb,
  },
})
export default class extends Vue {
  @Prop({ required: false }) public id?: number;

  private data: any = null;

  public async mounted() {
    this.data = await LoadingHandler(() => FAQRepository.findById(this.id!));

    setTitle((this.data || {}).title + "|よくある質問");
    dispatchInitialized();
  }
}
